import React from "react";

function DragAndDropFiles({
  getRootProps,
  getInputProps,
  open,
  acceptedFiles,
}) {
  const files = acceptedFiles.map((file) => (
    <li className="file-name" key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div className="container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p className="style-text">
          Добавьте файл формата эксель, содержащий информацию о группе
        </p>
        <p className="style-text2">Поддерживаемые форматы: XLSX</p>
        <button className="btn-upload" type="button" onClick={open}>
          Загрузить файл
        </button>
        {files}
      </div>
    </div>
  );
}

export default DragAndDropFiles;
