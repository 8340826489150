import React from 'react'

function Rest() {
    return (
        <div className='rest'>
            <span className='menu-title'>Ресторан</span>
            <span className='menu-edit'>Создать/редактировать</span>
        </div>
    )
}

export default Rest;