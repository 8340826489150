import { useEffect, useState } from "react";
import ModalCreateCourse from "../components/ModalCreateCourse";
import ModalSelectCourse from "../components/ModalSelectCourse";
import { baseUrl, baseHeaders } from "../utils/utils";

function ModalAddCourse({ addCourse, setAddCourse, period, day, getMenu }) {
  const [createCourse, setCreateCourse] = useState(false);
  const [selectCourse, setSelectCourse] = useState(false);
  const [selectDish, setSelectDish] = useState([]);

  function getAvailableCourses() {
    fetch(
      `${baseUrl}/admin/dishes/all/available?meal_type=${period.toUpperCase()}`,
      {
        headers: {
          ...baseHeaders,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setSelectDish(data);
      });
  }

  useEffect(() => {
    getAvailableCourses();
  }, []);

  return (
    <>
      <div
        className={
          addCourse ? "modal-wrap-add-course active" : "modal-wrap-add-course"
        }
      >
        <div className="modal-add-course">
          <div className="modal-add-course-title">
            <span className="modal-add-event-title">Добавить блюдо</span>
            <svg
              onClick={() => setAddCourse(false)}
              className="add-event-btn-close"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.75796 6.34375L6.34375 7.75797L10.5864 12.0006L6.34375 16.2432L7.75796 17.6575L12.0006 13.4148L16.2432 17.6575L17.6575 16.2432L13.4148 12.0006L17.6575 7.75796L16.2432 6.34375L12.0006 10.5864L7.75796 6.34375Z"
                fill="#F7F8F5"
              />
            </svg>
          </div>
          <div className="btn-course-variant">
            <button
              className="btn-select-course"
              onClick={() => setSelectCourse(true)}
            >
              Выбрать существующее
            </button>
            <button
              className="btn-create-course"
              onClick={() => setCreateCourse(true)}
            >
              Создать новое
            </button>
          </div>
        </div>
      </div>
      <ModalCreateCourse
        createCourse={createCourse}
        setCreateCourse={setCreateCourse}
        setAddCourse={setAddCourse}
        setSelectCourse={setSelectCourse}
        getAvailableCourses={getAvailableCourses}
      />
      <ModalSelectCourse
        day={day}
        period={period}
        selectCourse={selectCourse}
        setSelectCourse={setSelectCourse}
        getMenu={getMenu}
        setAddCourse={setAddCourse}
        selectDish={selectDish}
        setSelectDish={setSelectDish}
      />
    </>
  );
}

export default ModalAddCourse;
