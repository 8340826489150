import { baseUrl, baseHeaders } from "../utils/utils";

function ModalDeleteCourse({ deleteCourse, setDeleteCourse, getDishes }) {
  function delCourse() {
    fetch(`${baseUrl}/admin/dishes/${deleteCourse.id}`, {
      method: "DELETE",
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        getDishes();
        setDeleteCourse(false);
      });
  }
  return (
    <div
      className={
        deleteCourse ? "modal-wrap-add-event active" : "modal-wrap-add-event"
      }
    >
      <div className="modal-delete-event">
        <div className="modal-style-delete-person">
          <span className="modal-delete-person-title">Удаление блюда</span>
          <svg
            onClick={() => setDeleteCourse(false)}
            className="add-person-btn-close"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.75796 6.34375L6.34375 7.75797L10.5864 12.0006L6.34375 16.2432L7.75796 17.6575L12.0006 13.4148L16.2432 17.6575L17.6575 16.2432L13.4148 12.0006L17.6575 7.75796L16.2432 6.34375L12.0006 10.5864L7.75796 6.34375Z"
              fill="#494B42"
            />
          </svg>
        </div>
        <div className="center">
          <p className="delete-person-question">
            Вы точно хотите удалить блюдо из меню?
          </p>
          <p className="delete-person-text">
            Блюдо не удалится из системы,
            <br /> чтобы удалить его полностью пройдите в раздел “Все блюда”
          </p>
        </div>
        <div className="btn-wrap-delete-person">
          <button
            onClick={delCourse}
            className="btn-delete-person btn-delete-course"
          >
            Удалить
          </button>
          <button
            onClick={() => setDeleteCourse(false)}
            className="btn-cancel-person btn-cancel-course"
          >
            Отменить
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalDeleteCourse;
