import React from "react";
import { useNavigate } from "react-router-dom";

function Modal({ setModalOpen }) {
  const navigate = useNavigate();
  const goMain = () => navigate("/login", { replace: true });

  function closeModal() {
    setModalOpen(false);
  }

  function exit() {
    goMain();
    localStorage.removeItem("token");
  }

  return (
    <div className="modal-wrapper modal-exit">
      <div className="modal">
        <svg
          onClick={closeModal}
          className="btn-close-modal"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.758 10.3433L10.3438 11.7575L14.5864 16.0001L10.3438 20.2428L11.758 21.657L16.0006 17.4143L20.2432 21.657L21.6575 20.2428L17.4148 16.0001L21.6575 11.7575L20.2432 10.3433L16.0006 14.5859L11.758 10.3433Z"
            fill="#494B42"
          />
        </svg>
        <h1 className="modal-title">Выход из системы</h1>
        <p className="modal-question">
          Вы точно хотите выйти из административной панели?
        </p>
        <p className="modal-text">
          Вам придется заново проходить процесс авторизации
        </p>
        <div className="btn-wrap">
          <button onClick={exit} className="btn-exit">
            Выйти
          </button>
          <button onClick={closeModal} className="btn-cancel" href="/">
            Отменить
          </button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
