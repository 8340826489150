import RoomUser from "./RoomUser";

function RoomCard({
  rooms,
  item,
  editPerson,
  setEditPerson,
  checkinPers,
  setCheckinPers,
}) {
  const arr = [
    ...item?.users,
    ...new Array(item.capacity - item?.users.length),
  ];
  console.log(item);

  return (
    <div className="room-card">
      <div
        className={
          item.capacity - item.users.length > 0
            ? "room-header free-house flex-column"
            : "room-header flex-column"
        }
      >
        <span className="room-number">{item.number}</span>
      </div>
      <div className="room-users">
        {arr.map((user) => (
          <RoomUser
            user={user}
            editPerson={editPerson}
            setEditPerson={setEditPerson}
            checkinPers={checkinPers}
            setCheckinPers={setCheckinPers}
          />
        ))}
      </div>
    </div>
  );
}

export default RoomCard;
