import roomUser from "./../images/room-user.png";
import freeUser from "./../images/freeUser.png";

function RoomUser({ user }) {
  return user ? (
    <div className="room-user">
      <img className="user-icon" src={roomUser} alt="roomUser" />
      <div className="fio-mail flex-column">
        <span className="user-fio">
          {user?.last_name} {user?.first_name} {user?.surname}
        </span>
        <span className="user-mail">{user?.username}</span>
      </div>
    </div>
  ) : (
    <div className="room-user">
      <img className="user-icon" src={freeUser} alt="freeUser" />
      <span className="user-free">Свободно</span>
    </div>
  );
}

export default RoomUser;
