import { useEffect, useState } from "react";
import ModalCreateNewEvent from "./ModalCreateNewEvent";
import ModalSelectEvent from "./ModalSelectEvent";
import { baseUrl, baseHeaders } from "../utils/utils";

function ModalAddEvent({ active, setActive, getCardsEvent }) {
  const [create, setCreate] = useState(false);
  const [select, setSelect] = useState(false);
  const [events, setEvents] = useState([]);

  function getavailableEvents() {
    fetch(`${baseUrl}/admin/events/all/available`, {
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setEvents(data.items);
      });
  }

  useEffect(() => {
    getavailableEvents();
  }, []);

  return (
    <>
      <div
        className={
          active ? "modal-wrap-add-event active" : "modal-wrap-add-event"
        }
      >
        <div className="modal-add-event">
          <div className="modal-style">
            <span className="modal-add-event-title">Добавить мероприятие</span>
            <svg
              onClick={() => setActive(false)}
              className="add-event-btn-close"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.75796 6.34375L6.34375 7.75797L10.5864 12.0006L6.34375 16.2432L7.75796 17.6575L12.0006 13.4148L16.2432 17.6575L17.6575 16.2432L13.4148 12.0006L17.6575 7.75796L16.2432 6.34375L12.0006 10.5864L7.75796 6.34375Z"
                fill="#F7F8F5"
              />
            </svg>
          </div>
          <div className="btn-add-event-wrap">
            <button onClick={() => setSelect(true)} className="btn-add-event">
              Добавить существующее
            </button>
            <button
              onClick={() => setCreate(true)}
              className="btn-create-new-event"
            >
              Создать новое
            </button>
          </div>
        </div>
      </div>
      <ModalCreateNewEvent
        create={create}
        setCreate={setCreate}
        setActive={setActive}
        getCardsEvent={getCardsEvent}
        getavailableEvents={getavailableEvents}
      />
      <ModalSelectEvent
        select={select}
        setSelect={setSelect}
        setActive={setActive}
        getCardsEvent={getCardsEvent}
        events={events}
        setEvents={setEvents}
      />
    </>
  );
}

export default ModalAddEvent;
