import { useEffect, useState } from "react";
import HeaderAdmin from "../components/HeaderAdmin";
import edit from "../images/edit-descr.png";
import cancel from "../images/cancel-descr.png";
import del from "../images/delete-descr.png";
import CardPerson from "../components/PersonCard";
import plus from "../images/TableCell.png";
import ModalDeleteEvent from "../components/ModalDeleteEvent";
import ModalCancelEvent from "../components/ModalCancelEvent";
import { useParams } from "react-router-dom";
import { baseHeaders, baseUrl, getTime, parseDate } from "../utils/utils";
import ModalEditEventBtn from "../components/ModalEditEventBtn";
import ModalSelectPerson from "../components/ModalSelectPerson";

function EventDescr({ editEvent, setEditEvent }) {
  const [isOpen, setOpen] = useState(false);
  const [deleteEvent, setDeleteEvent] = useState(false);
  const [cancelEvent, setCancelEvent] = useState(false);
  const { id, date } = useParams();
  const [event, setEvent] = useState(null);
  const [selectPerson, setSelectPerson] = useState();
  const [person, setPerson] = useState([]);
  const [guest, setGuest] = useState([]);

  function getDescr() {
    fetch(`${baseUrl}/admin/events/${id}?d=${date}`, {
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        fetch(`${baseUrl}/admin/events/${id}?d=${data.date}`, {
          headers: {
            ...baseHeaders,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            setGuest(data.users);
          });
        setEvent(data);
      });
  }
  console.log(guest);
  useEffect(() => {
    getDescr();
  }, [id]);

  function SignOutPerson(guest) {
    fetch(`${baseUrl}/admin/users/${guest}/events/signout`, {
      method: "PUT",
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        event_id: event?.id,
        date: event?.date,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        getDescr();
        alert("Участник отписан от мероприятия");
      });
  }

  return (
    <>
      <HeaderAdmin setOpen={setOpen} isOpen={isOpen} />

      <div className="descr-title-wrap">
        <span className="descr-title">{event?.name}</span>
        <img
          onClick={() => setEditEvent(event)}
          className="btn-descr-edit"
          src={edit}
          alt="edit"
        />
        <img
          onClick={() => setCancelEvent(true)}
          className="btn-descr-cancel"
          src={cancel}
          alt="cancel"
        />
        <span className="strelka">&#8592; отменить мероприятие</span>
        <span className="strelka-right">
          удалить мероприятие навсегда &#8594;
        </span>
        <img
          onClick={() => setDeleteEvent(true)}
          className="btn-descr-delete"
          src={del}
          alt="del"
        />
      </div>
      <div className="descr-time-date">
        <span className="descr-time">
          {event?.time_start.toLocaleString().slice(0, -3)}-
          {event?.time_end.toLocaleString().slice(0, -3)}
        </span>
      </div>
      <div className="descr-info">
        <span className="descr-hour descr-item">
          {event?.time_start && getTime(event?.time_start, event?.time_end)}ч
        </span>
        <span className="descr-place descr-item">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.6654 6.60606C12.6654 10.0606 7.9987 14.6667 7.9987 14.6667C7.9987 14.6667 3.33203 10.0606 3.33203 6.60606C3.33203 3.95621 5.42137 2 7.9987 2C10.576 2 12.6654 3.95621 12.6654 6.60606ZM7.9987 8.66667C9.10327 8.66667 9.9987 7.77124 9.9987 6.66667C9.9987 5.5621 9.10327 4.66667 7.9987 4.66667C6.89413 4.66667 5.9987 5.5621 5.9987 6.66667C5.9987 7.77124 6.89413 8.66667 7.9987 8.66667Z"
              fill="#878980"
            />
          </svg>{" "}
          {event?.place}
        </span>
      </div>
      <div className="descr-text">{event?.description}</div>
      <div className="descr-info-wrap">
        <span className="descr-date">
          <svg
            className="user-pic"
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 7C8.65685 7 10 5.15685 10 3.5C10 1.84315 9.5 0 7 0C4.5 0 4 1.84315 4 3.5C4 5.15685 5.34315 7 7 7Z"
              fill="#494B42"
            />
            <path
              d="M7 9C1.55556 9 1.19209e-06 10 0 15L14 15C14 10 12.4444 9 7 9Z"
              fill="#494B42"
            />
          </svg>
          {event?.places_left}/{event?.capacity}
        </span>
        <span style={{ color: "#878980" }} className="descr-date">
          {event?.date && parseDate(event?.date)}
        </span>
      </div>
      <div className="list-wrap">
        <div className="header-list-wrap">
          <span className="list-item list-item-home">Дом</span>
          <span className="list-item list-item-room">Номер</span>
          <span className="list-item list-item-fio">ФИО</span>
          <span className="list-item list-item-mail">Почта</span>
          <span className="list-item list-item-start">Дата заезда</span>
          <span className="list-item list-item-end">Дата выезда</span>
          <span className="list-item list-item-end">Баллы</span>
        </div>

        {guest?.map((item) => (
          <CardPerson item={item} SignOutPerson={SignOutPerson} />
        ))}

        <div className="add-person" onClick={() => setSelectPerson(true)}>
          <span className="add-person-bg">
            <img src={plus} alt="add-person" />
          </span>
          <span className="bg"></span>
        </div>
      </div>

      <ModalEditEventBtn
        editEvent={editEvent}
        setEditEvent={setEditEvent}
        getDescr={getDescr}
      />
      <ModalDeleteEvent
        deleteEvent={deleteEvent}
        setDeleteEvent={setDeleteEvent}
      />

      <ModalCancelEvent
        cancelEvent={cancelEvent}
        setCancelEvent={setCancelEvent}
      />

      <ModalSelectPerson
        selectPerson={selectPerson}
        setSelectPerson={setSelectPerson}
        person={person}
        setPerson={setPerson}
        eventDate={event?.date}
        getDescr={getDescr}
      />
    </>
  );
}

export default EventDescr;
