import userAvatar from "./../images/UserAvatar.png";
import Stars from "./Stars";

function FeedbackCard({ item }) {
  return (
    <div className="feedback-card">
      <div className="wrap-head">
        <img src={userAvatar} alt="alt" />
        <div className="flex-column">
          <span className="user-fio-feed">
            {item.user.last_name} {item.user.first_name} {item.user.surname}
          </span>
          <span className="user-mail-feed">{item.user.username}</span>
        </div>
      </div>
      <div className="start-container">
        <Stars item={item} />
      </div>
      <div className="feedback-text">{item.comment}</div>
    </div>
  );
}

export default FeedbackCard;
