import { useLocation, useNavigate } from "react-router-dom";
import { parseDate } from "../utils/utils";
import deleteItem from "./../images/delete-item.png";

function CardPerson({ item, setDelPerson, setCheckin, SignOutPerson, group }) {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(item);

  return (
    <div className="person-list-wrap">
      {!item?.room?.id ? (
        <button
          disabled={location.pathname.startsWith("/event_descr")}
          className="settle-person"
          onClick={() => setCheckin(item)}
        >
          Заселить
        </button>
      ) : (
        <>
          <span className="person-item person-item-home">
            {item.room.house.number}
          </span>
          <span className="person-item person-item-room">
            {item.room.number}
          </span>
        </>
      )}
      <span
        className="person-item person-item-fio"
        onClick={() => navigate(`/person_descr/${item.id}`)}
      >
        {item.last_name} {item.first_name} {item.surname}
      </span>
      <span className="person-item person-item-mail">{item.username}</span>
      {!item?.start_date ? (
        <span className="person-item person-item-start">дата не выбрана</span>
      ) : (
        <span className="person-item person-item-start">
          {parseDate(item?.start_date)}
        </span>
      )}
      {!item?.end_date ? (
        <span className="person-item person-item-end">дата не выбрана</span>
      ) : (
        <span className="person-item person-item-end">
          {parseDate(item?.end_date)}
        </span>
      )}
      <span className="person-item person-item-start">{item.stars}</span>
      {location.pathname.startsWith("/event_descr") && (
        <span className="signout-person" onClick={() => SignOutPerson(item.id)}>
          Отписать
        </span>
      )}
      {location.pathname.startsWith("/group_list") && (
        <button onClick={() => setDelPerson(item.id)} className="delete-person">
          <img src={deleteItem} alt="delete" />
        </button>
      )}
    </div>
  );
}

export default CardPerson;
