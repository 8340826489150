import { useLocation, useNavigate } from "react-router-dom";
import eventImg from "./../images/EventImg.png";
import { getTime } from "../utils/utils";

function EventCard({ item, editEvent, setEditEvent, date }) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <div className="event-card-wrap">
        <img
          onClick={() => navigate(`/event_descr/${item.id}/${date}`)}
          className="event-img"
          src={eventImg}
          alt="event-img"
        />
        <span className="event-hour">
          {getTime(item.time_start, item.time_end)}ч
        </span>
        <span className="points">{item.reward}</span>
        <span className="event-name">{item.name}</span>
        <span className="geo-text">
          {item.time_start.toLocaleString().slice(0, -3)}-
          {item.time_end.toLocaleString().slice(0, -3)}
        </span>
        <span className="geo">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.6654 6.60606C12.6654 10.0606 7.9987 14.6667 7.9987 14.6667C7.9987 14.6667 3.33203 10.0606 3.33203 6.60606C3.33203 3.95621 5.42137 2 7.9987 2C10.576 2 12.6654 3.95621 12.6654 6.60606ZM7.9987 8.66667C9.10327 8.66667 9.9987 7.77124 9.9987 6.66667C9.9987 5.5621 9.10327 4.66667 7.9987 4.66667C6.89413 4.66667 5.9987 5.5621 5.9987 6.66667C5.9987 7.77124 6.89413 8.66667 7.9987 8.66667Z"
              fill="#494B42"
            />
          </svg>
          {item.place}
        </span>
        <span className="geo">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 7C8.65685 7 10 5.15685 10 3.5C10 1.84315 9.5 0 7 0C4.5 0 4 1.84315 4 3.5C4 5.15685 5.34315 7 7 7Z"
              fill="#494B42"
            />
            <path
              d="M7 9C1.55556 9 1.19209e-06 10 0 15L14 15C14 10 12.4444 9 7 9Z"
              fill="#494B42"
            />
          </svg>{" "}
          {item.places_left}/{item.capacity}
        </span>

        <button
          onClick={() => setEditEvent(item)}
          className="event-edit-btn"
          disabled={location.pathname.startsWith("/person_descr")}
        >
          Редактировать
        </button>
      </div>
    </>
  );
}

export default EventCard;
