import { useNavigate } from "react-router-dom";
import { baseUrl, baseHeaders } from "../utils/utils";
import CourseCard from "./CourseCard";

function MenuForm({
  setAddCourse,
  editCourse,
  setEditCourse,
  deleteCourse,
  setDeleteCourse,
  period,
  menu,
  setMenu,
  getMenu,
  day,
  timeStart,
  timeEnd,
  setTimeStart,
  setTimeEnd,
  dishesCard,
  setDishesCard,
}) {
  const navigate = useNavigate();

  function handleSubmit() {
    if (!timeStart || !timeEnd) {
      alert("Выберите время");
      return;
    }
    fetch(`${baseUrl}/admin/menu/select`, {
      method: "PUT",
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        meal_type_id: period.toUpperCase(),
        dishes_ids: dishesCard,
        day: day,
        start_time: timeStart,
        end_time: timeEnd,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        alert("Изменения сохранены");
        getMenu();
      });
  }

  return (
    <>
      <div className="courses-time-wrap">
        <input
          onChange={(event) => {
            setTimeStart(event.target.value);
          }}
          value={timeStart}
          name="start-time"
          id="course-start"
          className="input-time"
          type="time"
          placeholder="00:00"
        />{" "}
        -
        <input
          onChange={(event) => {
            setTimeEnd(event.target.value);
          }}
          value={timeEnd}
          name="end-time"
          id="course-end"
          className="input-time input-time-end"
          type="time"
        />
        <button onClick={handleSubmit} className="all-menu all-menu-save">
          Сохранить изменения после смены времени или чекбоксов
        </button>
        <button className="all-menu" onClick={(day) => navigate(`/restaurant`)}>
          Все блюда
        </button>
      </div>

      <div className="courses-title-wrap">
        <h1 className="courses-title">{menu?.type?.name}</h1>
        <svg
          onClick={() => setAddCourse(true)}
          className="btn-plus"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="40" height="40" rx="2" fill="#C9D895" />
          <path d="M21 10H19V19H10V21H19V30H21V21H30V19H21V10Z" fill="#fff" />
        </svg>
      </div>

      <div className="courses-card-container">
        {menu?.dishes?.map((item) => (
          <CourseCard
            item={item}
            editCourse={editCourse}
            setEditCourse={setEditCourse}
            deleteCourse={deleteCourse}
            setDeleteCourse={setDeleteCourse}
            dishesCard={dishesCard}
            setDishesCard={setDishesCard}
          />
        ))}
      </div>
    </>
  );
}

export default MenuForm;
