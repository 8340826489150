import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect } from "react";
import { baseHeaders, baseUrl } from "../utils/utils";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

function ModalSelectPerson({
  selectPerson,
  setSelectPerson,
  person,
  setPerson,
  eventDate,
  getDescr,
}) {
  const { id } = useParams();

  const validationsSchema = Yup.object().shape({
    user_id: Yup.string().required("Вы не выбрали гостя"),
  });

  useEffect(() => {
    fetch(`${baseUrl}/admin/users/all/actual`, {
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setPerson(data.items);
      });
  }, []);

  function sendSelectPerson(values) {
    fetch(`${baseUrl}/admin/users/${values.user_id}/events/signup`, {
      method: "PUT",
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        event_id: id,
        date: eventDate,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setSelectPerson(false);
        getDescr();
      });
  }

  return (
    <div
      className={
        selectPerson ? "modal-wrap-add-event active" : "modal-wrap-add-event"
      }
    >
      <div className="modal-select-event">
        <div className="event-wrap margin-bottom">
          <span className="create-event-title">Добавить участника</span>
          <svg
            onClick={() => setSelectPerson(false)}
            className="add-event-btn-close"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.75796 6.34375L6.34375 7.75797L10.5864 12.0006L6.34375 16.2432L7.75796 17.6575L12.0006 13.4148L16.2432 17.6575L17.6575 16.2432L13.4148 12.0006L17.6575 7.75796L16.2432 6.34375L12.0006 10.5864L7.75796 6.34375Z"
              fill="#F7F8F5"
            />
          </svg>
        </div>
        <span className="select-event-label">Гости</span>
        <Formik
          initialValues={{
            user_id: person[0]?.id,
          }}
          onSubmit={sendSelectPerson}
          validationSchema={validationsSchema}
        >
          {({ dirty, isValid }) => (
            <Form>
              <Field name={"user_id"} className="select-event" as="select">
                <option>Выберите гостя</option>
                {person?.map((item) => (
                  <option value={item.id}>
                    {item.last_name} {item.first_name} {item.surname}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                component={`p`}
                name={`user_id`}
                className="err_message"
              />
              <div className="btn-event-wrap">
                <button
                  onClick={() => setSelectPerson(false)}
                  className="btn-event-cancel"
                >
                  Отменить
                </button>
                <button
                  type="submit"
                  className={
                    !(dirty && isValid)
                      ? "btn-event-save"
                      : "btn-event-save active"
                  }
                >
                  Сохранить
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ModalSelectPerson;
