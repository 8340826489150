import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { baseUrl, baseHeaders } from "../utils/utils";

function ModalSelectCourse({
  selectCourse,
  setSelectCourse,
  period,
  day,
  getMenu,
  setAddCourse,
  selectDish,
}) {
  const validationsSchema = Yup.object().shape({
    dishes_ids: Yup.string().required("Блюдо не выбрано"),
  });

  function sendSelectDish(values) {
    fetch(`${baseUrl}/admin/menu/global`, {
      method: "POST",
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        dishes_ids: [+values.dishes_ids],
        meal_type_id: period.toUpperCase(),
        day: day,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        getMenu();
        setSelectCourse(false);
        setAddCourse(false);
      });
  }

  return (
    <div
      className={
        selectCourse
          ? "modal-wrap-select-course active"
          : "modal-wrap-select-course"
      }
    >
      <div className="modal-select-course">
        <div className="modal-add-course-title">
          <span className="modal-add-event-title">Выбрать блюдо</span>
          <svg
            onClick={() => setSelectCourse(false)}
            className="add-event-btn-close"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.75796 6.34375L6.34375 7.75797L10.5864 12.0006L6.34375 16.2432L7.75796 17.6575L12.0006 13.4148L16.2432 17.6575L17.6575 16.2432L13.4148 12.0006L17.6575 7.75796L16.2432 6.34375L12.0006 10.5864L7.75796 6.34375Z"
              fill="#F7F8F5"
            />
          </svg>
        </div>
        <span className="select-event-label">Блюдо</span>
        <Formik
          initialValues={{
            dishes_ids: "",
          }}
          onSubmit={sendSelectDish}
          validationSchema={validationsSchema}
        >
          {({ dirty, isValid }) => (
            <Form>
              <Field
                as="select"
                className="select-event select-course"
                name={"dishes_ids"}
              >
                <option value="">Выберите из ранее созданных</option>
                {selectDish?.items?.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </Field>
              <ErrorMessage
                component={`p`}
                name={`dishes_ids`}
                className="err_message"
              />
              <div className="btn-event-wrap">
                <button
                  onClick={() => setSelectCourse(false)}
                  className="btn-event-cancel"
                >
                  Отменить
                </button>
                <button
                  type="submit"
                  className={
                    !(dirty && isValid)
                      ? "btn-event-save"
                      : "btn-event-save active"
                  }
                >
                  Сохранить
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ModalSelectCourse;
