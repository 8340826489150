import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { baseUrl, baseHeaders } from "../utils/utils";

function ModalCreateCourse({
  createCourse,
  setCreateCourse,
  setAddCourse,
  setSelectCourse,
  getAvailableCourses,
}) {
  const validationsSchema = Yup.object().shape({
    name: Yup.string().required("Поле не заполнено"),
  });

  function createNewDish(values, { resetForm }) {
    fetch(`${baseUrl}/admin/dishes`, {
      method: "POST",
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((data) => {
        setCreateCourse(false);
        setAddCourse(false);
        setSelectCourse();
        getAvailableCourses();
        resetForm();
      });
  }

  return (
    <div
      className={
        createCourse
          ? "modal-wrap-create-course active"
          : "modal-wrap-create-course"
      }
    >
      <div className="modal-create-course">
        <div className="modal-add-course-title">
          <span className="modal-add-event-title">Добавить блюдо</span>
          <svg
            onClick={() => setCreateCourse(false)}
            className="add-event-btn-close"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.75796 6.34375L6.34375 7.75797L10.5864 12.0006L6.34375 16.2432L7.75796 17.6575L12.0006 13.4148L16.2432 17.6575L17.6575 16.2432L13.4148 12.0006L17.6575 7.75796L16.2432 6.34375L12.0006 10.5864L7.75796 6.34375Z"
              fill="#F7F8F5"
            />
          </svg>
        </div>
        <Formik
          initialValues={{
            name: "",
            description: "",
          }}
          validationSchema={validationsSchema}
          onSubmit={createNewDish}
        >
          {({ dirty, isValid }) => (
            <Form>
              <div className="flex margin-bottom">
                <div className="flex-column">
                  <label htmlFor={"name"} className="label-style-person">
                    Название
                  </label>
                  <Field
                    className="add-event-name create-course-name"
                    name={"name"}
                    placeholder="Назовите блюдо"
                    autocomplete="off"
                  />
                  <ErrorMessage
                    component={`p`}
                    name={`name`}
                    className="err_message"
                  />
                </div>
              </div>
              <div className="flex-column margin-bottom">
                <label htmlFor={"description"} className="label-style-person">
                  Описание
                </label>
                <Field
                  as="textarea"
                  className="add-event-descr create-course-descr"
                  name={"description"}
                  placeholder="Опишите блюдо"
                />
                <ErrorMessage
                  component={`p`}
                  name={`description`}
                  className="err_message"
                />
              </div>
              <div className="btn-event-wrap">
                <button
                  onClick={() => setCreateCourse(false)}
                  className="btn-event-cancel"
                >
                  Отменить
                </button>
                <button
                  type="submit"
                  className={
                    !(dirty && isValid)
                      ? "btn-event-save"
                      : "btn-event-save active"
                  }
                >
                  Сохранить
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ModalCreateCourse;
