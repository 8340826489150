import { useState } from "react";
import HeaderAdmin from "./../components/HeaderAdmin";
import { useNavigate } from "react-router-dom";
import AllCourses from "../components/AllCourses";

function RestPage({
  deleteCourse,
  setDeleteCourse,
  editCourse,
  setEditCourse,
}) {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const [allCourses, setAllCourses] = useState(false);

  return (
    <>
      <HeaderAdmin setOpen={setOpen} isOpen={isOpen} />

      <div className="container-rest">
        <h1 className="rest-title">Ресторан</h1>
      </div>
      <div className="wrap-rest-btn">
        <button
          className={allCourses ? "create-menu" : "create-menu"}
          onClick={() => navigate(`/courses`)}
        >
          Создание меню
        </button>
        <button
          className={allCourses ? "all-menu active" : "all-menu"}
          onClick={(day) => setAllCourses(true)}
        >
          Все блюда
        </button>
      </div>

      <AllCourses
        allCourses={allCourses}
        setAllCourses={setAllCourses}
        deleteCourse={deleteCourse}
        setDeleteCourse={setDeleteCourse}
        editCourse={editCourse}
        setEditCourse={setEditCourse}
      />
    </>
  );
}

export default RestPage;
