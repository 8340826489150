import { ErrorMessage, Field, Form, Formik } from "formik";
import DatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { baseHeaders, baseUrl } from "../utils/utils";
import * as Yup from "yup";

function ModalAddPerson({
  modalActive,
  setModalActive,
  groupStart,
  groupEnd,
  renderGroup,
}) {
  const [startDate, setStartDate] = useState(new Date(groupStart));
  const [endDate, setEndDate] = useState(new Date(groupEnd));
  const [houses, setHouses] = useState([]);
  const [houseId, setHouseId] = useState(null);
  const [rooms, setRooms] = useState([]);
  const { id } = useParams();

  const validationsSchema = Yup.object().shape({
    username: Yup.string()
      .email("Почта введена неверно")
      .required("Поле не заполнено"),
    first_name: Yup.string().required("Поле не заполнено"),
    last_name: Yup.string().required("Поле не заполнено"),
    surname: Yup.string().required("Поле не заполнено"),
    start_date: Yup.string().required("Дата заезда не выбрана"),
    end_date: Yup.string().required("Дата выезда не выбрана"),
    home: Yup.string().required("Дом не выбран"),
    room_id: Yup.string().required("Комната не выбрана"),
  });

  useEffect(() => {
    fetch(`${baseUrl}/admin/houses?free=true&group_id=${id}`, {
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setHouses(data.items);
        setHouseId(data.items[0]?.id);
      });
  }, []);

  useEffect(() => {
    if (houseId) {
      fetch(
        `${baseUrl}/admin/rooms?house_id=${houseId}&free=true&group_id=${id}`,
        {
          headers: {
            ...baseHeaders,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setRooms(data.items);
        });
    }
  }, [houseId]);

  function createUser(values, { resetForm }) {
    fetch(`${baseUrl}/admin/users`, {
      method: "POST",
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((data) => {
        renderGroup();
        setModalActive(false);
        resetForm();
      })
      .catch((err) => {
        console.log(err);
        alert("Выберите другую комнату");
      });
  }

  return (
    <div
      className={
        modalActive ? "modal-wrap-add-group active" : "modal-wrap-add-group"
      }
    >
      <div className="modal-add-group">
        <div className="flex modal-style">
          <span className="modal-add-group-title">Добавить гостя</span>
          <svg
            onClick={() => setModalActive(false)}
            className="add-person-btn-close"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.75796 6.34375L6.34375 7.75797L10.5864 12.0006L6.34375 16.2432L7.75796 17.6575L12.0006 13.4148L16.2432 17.6575L17.6575 16.2432L13.4148 12.0006L17.6575 7.75796L16.2432 6.34375L12.0006 10.5864L7.75796 6.34375Z"
              fill="white"
            />
          </svg>
        </div>
        <Formik
          initialValues={{
            username: "",
            first_name: "",
            last_name: "",
            surname: "",
            start_date: "",
            end_date: "",
            room_id: "",
            role: "GUEST",
            stars: 0,
            group_id: id,
          }}
          onSubmit={createUser}
          enableReinitialize={true}
          validationSchema={validationsSchema}
        >
          {({ setFieldValue, dirty, isValid }) => (
            <Form className="formwrap-add-person">
              <div className="fio-wrap flex">
                <div className="margin-right">
                  <label htmlFor={"last_name"} className="label-style-person">
                    Фамилия
                  </label>
                  <br />
                  <Field
                    className="add-person-fio"
                    name={"last_name"}
                    placeholder="Иванов"
                    autocomplete="off"
                  />
                  <ErrorMessage
                    component={`p`}
                    name={`last_name`}
                    className="err_message"
                  />
                </div>
                <div className="margin-right">
                  <label htmlFor={"first_name"} className="label-style-person">
                    Имя
                  </label>
                  <br />
                  <Field
                    className="add-person-fio"
                    name={"first_name"}
                    placeholder="Иван"
                    autocomplete="off"
                  />
                  <ErrorMessage
                    component={`p`}
                    name={`first_name`}
                    className="err_message"
                  />
                </div>
                <div className="margin-right">
                  <label htmlFor={"surname"} className="label-style-person">
                    Отчество
                  </label>
                  <br />
                  <Field
                    className="add-person-fio"
                    name={"surname"}
                    placeholder="Иванович"
                    autocomplete="off"
                  />
                  <ErrorMessage
                    component={`p`}
                    name={`surname`}
                    className="err_message"
                  />
                </div>
              </div>
              <label htmlFor={"username"} className="label-style-person">
                Почта
              </label>
              <br />
              <Field
                className="add-person-mail"
                name={"username"}
                placeholder="ivan.ii@gazprom-neft.ru"
                autocomplete="off"
              />
              <ErrorMessage
                component={`p`}
                name={`username`}
                className="err_message"
              />
              <div className="flex">
                <div className="home-room">
                  <label htmlFor={"home"} className="label-style-person">
                    Дом
                  </label>
                  <br />
                  <Field
                    onChange={(event) => {
                      setFieldValue("home", event.target.value);
                      setHouseId(event.target.value);
                    }}
                    className="select-home"
                    as="select"
                    name={"home"}
                    placeholder="Выберите дом"
                  >
                    <option></option>
                    {houses.map((item) => (
                      <option className="style-option" value={item.id}>
                        {item.number}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    component={`p`}
                    name={`home`}
                    className="err_message"
                  />
                </div>
                <div>
                  <label htmlFor={"room_id"} className="label-style-person">
                    Комната
                  </label>
                  <br />
                  <Field className="select-room" as="select" name={"room_id"}>
                    <option></option>
                    {rooms?.map((item) => (
                      <option className="style-option" value={item.id}>
                        {item.number}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    component={`p`}
                    name={`room_id`}
                    className="err_message"
                  />
                </div>
              </div>

              <div className="flex wrap-datepicker">
                <div className="flex-start">
                  <label htmlFor={"start_date"} className="label-style-person">
                    Дата заезда
                  </label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setFieldValue(
                        "start_date",
                        date.toISOString().split("T")[0]
                      );
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    minDate={new Date(groupStart)}
                    maxDate={endDate}
                    name={"start_date"}
                    locale="ru"
                    dateFormat={"dd.MM.yyyy"}
                  />
                  <ErrorMessage
                    component={`p`}
                    name={`start_date`}
                    className="err_message"
                  />
                </div>
                <div className="flex-start">
                  <label htmlFor={"end_date"} className="label-style-person">
                    Дата заезда
                  </label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                      setFieldValue(
                        "end_date",
                        date.toISOString().split("T")[0]
                      );
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    maxDate={new Date(groupEnd)}
                    name={"end_date"}
                    locale="ru"
                    dateFormat={"dd.MM.yyyy"}
                  />
                  <ErrorMessage
                    component={`p`}
                    name={`end_date`}
                    className="err_message"
                  />
                </div>
              </div>

              <div className="btn-wrap-add-person">
                <span
                  className="btn-add-person-cancel"
                  onClick={() => setModalActive(false)}
                >
                  Отменить
                </span>
                <button
                  type="submit"
                  className={
                    !(dirty && isValid)
                      ? "btn-add-person-save"
                      : "btn-add-person-save-active"
                  }
                >
                  Сохранить
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ModalAddPerson;
