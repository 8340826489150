import DatePicker from "react-datepicker";
import { useState } from "react";
import * as Yup from "yup";
import DragAndDropFiles from "./../components/DragAndDropFiles";
import { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import { baseUrl } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Form, Formik } from "formik";
import { useDropzone } from "react-dropzone";
registerLocale("ru", ru);

function ModalAddGroup({ active, setActive }) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const navigate = useNavigate();

  const validationsSchema = Yup.object().shape({
    start_date: Yup.string().required("Дата заезда не выбрана"),
    end_date: Yup.string().required("Дата выезда не выбрана"),
  });

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: {
      "files/*": [".xls", ".xlsx"],
    },
  });

  function createGroup({ resetForm }) {
    let reader = new FileReader();
    const formData = new FormData();
    formData.append("start_date", startDate.toISOString().split("T")[0]);
    formData.append("end_date", endDate.toISOString().split("T")[0]);
    if (acceptedFiles.length) {
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = function (values) {
        formData.append("file", reader.result);
        fetch(`${baseUrl}/admin/groups/excel`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            start_date: formData.get("start_date"),
            end_date: formData.get("end_date"),
            file: formData.get("file"),
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.detail) {
              alert("Неправильно заполнена таблица");
            } else {
              navigate(`/group_list/${data.group.id}`, { replace: true });
            }
          })
          .catch((err) => {
            alert("Сохраните таблицу в версии Excel 2007-2019");
          });
      };
    } else {
      fetch(`${baseUrl}/admin/groups`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          start_date: formData.get("start_date"),
          end_date: formData.get("end_date"),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          navigate(`/group_list/${data.id}`, { replace: true });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
    resetForm();
  }

  return (
    <div
      className={
        active ? "modal-wrap-add-group active" : "modal-wrap-add-group"
      }
    >
      <div className="modal-add-group">
        <div className="flex modal-style">
          <span className="modal-add-group-title">Добавить группу</span>
          <svg
            onClick={() => setActive(false)}
            className="add-group-btn-close"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.75796 6.34375L6.34375 7.75797L10.5864 12.0006L6.34375 16.2432L7.75796 17.6575L12.0006 13.4148L16.2432 17.6575L17.6575 16.2432L13.4148 12.0006L17.6575 7.75796L16.2432 6.34375L12.0006 10.5864L7.75796 6.34375Z"
              fill="white"
            />
          </svg>
        </div>
        <Formik
          initialValues={{
            start_date: "",
            end_date: "",
          }}
          onSubmit={createGroup}
          validationSchema={validationsSchema}
        >
          {({ setFieldValue, dirty, isValid }) => (
            <Form>
              <div className="flex modal-style2">
                <span className="date-period-start">Дата заезда</span>
                <span className="date-period-end">Дата выезда</span>
              </div>
              <div className="flex modal-style3">
                <div className="flex-column">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setFieldValue(
                        "start_date",
                        date.toISOString().split("T")[0]
                      );
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    locale="ru"
                    dateFormat={"dd.MM.yyyy"}
                    name={"start_date"}
                  />
                  <ErrorMessage
                    component={`p`}
                    name={`start_date`}
                    className="err_message"
                  />
                </div>
                <div className="flex-column">
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                      setFieldValue(
                        "end_date",
                        date.toISOString().split("T")[0]
                      );
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    locale="ru"
                    dateFormat={"dd.MM.yyyy"}
                    name={"end_date"}
                  />
                  <ErrorMessage
                    component={`p`}
                    name={`end_date`}
                    className="err_message"
                  />
                </div>
              </div>
              <DragAndDropFiles
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                open={open}
                acceptedFiles={acceptedFiles}
              />

              <div className="files-btn-wrap">
                <span
                  className="modal-btn-cancel"
                  onClick={() => setActive(false)}
                >
                  Отменить
                </span>
                <button
                  type="submit"
                  className={
                    !(dirty && isValid)
                      ? "modal-btn-save"
                      : "modal-btn-save active"
                  }
                >
                  Сохранить
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ModalAddGroup;
