import { useEffect, useState } from "react";
import HeaderAdmin from "../components/HeaderAdmin";
import ModalAddCourse from "../components/ModalAddCourse";
import ModalEditCourse from "../components/ModalEditCourse";
import MenuForm from "../components/MenuForm";
import { baseUrl, baseHeaders, getSelectedIds } from "../utils/utils";

function CreateCourse({
  editCourse,
  setEditCourse,
  deleteCourse,
  setDeleteCourse,
}) {
  const [isOpen, setOpen] = useState(false);
  const [addCourse, setAddCourse] = useState(false);
  const [day, setDay] = useState(1);
  const [period, setPeriod] = useState("breakfast");
  const [menu, setMenu] = useState([]);
  const [timeStart, setTimeStart] = useState("");
  const [timeEnd, setTimeEnd] = useState("");
  const [dishesCard, setDishesCard] = useState([]);

  function getMenu() {
    fetch(`${baseUrl}/admin/menu?day=${day}`, {
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setMenu(data[period]);
        setDishesCard(getSelectedIds(data[period].dishes));
        setTimeStart(data[period].start_time);
        setTimeEnd(data[period].end_time);
      });
  }

  useEffect(() => {
    getMenu();
  }, [day, period]);

  console.log(day);

  return (
    <>
      <HeaderAdmin setOpen={setOpen} isOpen={isOpen} />

      <div className="courses-btn-wrap">
        <button
          onClick={() => setPeriod("breakfast")}
          className={`btn-course ${
            period === "breakfast" ? "active" : ""
          } btn-breakfast`}
        >
          Завтрак
        </button>

        <button
          onClick={() => setPeriod("lunch")}
          className={`btn-course ${
            period === "lunch" ? "active" : ""
          } btn-lunch`}
        >
          Обед
        </button>
        <button
          onClick={() => setPeriod("dinner")}
          className={`btn-course ${
            period === "dinner" ? "active" : ""
          } btn-dinner`}
        >
          Ужин
        </button>
      </div>

      <div className="wrap-create-menu">
        <select
          onChange={(event) => {
            setDay(event.target.value);
          }}
          className="month-picker-select day-picker-select select-day-menu"
        >
          {[...new Array(10).fill(null)].map((element, index) => (
            <option className="month-picker-option" value={index + 1}>
              День {index + 1}
            </option>
          ))}
        </select>
      </div>

      <MenuForm
        addCourse={addCourse}
        setAddCourse={setAddCourse}
        editCourse={editCourse}
        setEditCourse={setEditCourse}
        deleteCourse={deleteCourse}
        setDeleteCourse={setDeleteCourse}
        period={period}
        menu={menu}
        setMenu={setMenu}
        getMenu={getMenu}
        day={day}
        timeStart={timeStart}
        timeEnd={timeEnd}
        setTimeStart={setTimeStart}
        setTimeEnd={setTimeEnd}
        dishesCard={dishesCard}
        setDishesCard={setDishesCard}
      />

      <ModalAddCourse
        addCourse={addCourse}
        setAddCourse={setAddCourse}
        period={period}
        day={day}
        getMenu={getMenu}
      />
      <ModalEditCourse
        editCourse={editCourse}
        setEditCourse={setEditCourse}
        getMenu={getMenu}
      />
    </>
  );
}

export default CreateCourse;
