import { useEffect, useState } from "react";
import HeaderAdmin from "./../components/HeaderAdmin";
import editUser from "./../images/edit-descr.png";
import userAvatar from "./../images/UserAvatar.png";
import userCode from "./../images/UserCode.png";
import userDate from "./../images/calendar.png";
import userHome from "./../images/home.png";
import userRoom from "./../images/room.png";
import userPoints from "./../images/points.png";
import {
  baseHeaders,
  baseUrl,
  compareDataEvents,
  parseDate,
} from "../utils/utils";
import { useParams } from "react-router-dom";
import EventCardDate from "../components/EventCardDate";
import ModalEditPerson from "../components/ModalEditPerson";
import ModalAddPoints from "../components/ModalAddPoints";

function PersonPage() {
  const [isOpen, setOpen] = useState(false);
  const [person, setPerson] = useState();
  const { id } = useParams();
  const [eventsPers, setEventsPers] = useState([]);
  const [offset, setOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [editPerson, setEditPerson] = useState(null);
  const [addPoints, setAddPoints] = useState(false);

  function getPerson() {
    fetch(`${baseUrl}/admin/users/${id}`, {
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setPerson(data);
      });
  }

  useEffect(() => {
    getPerson();
  }, [id]);

  function getEventsCards() {
    fetch(`${baseUrl}/admin/users/${id}/events?limit=6&offset=${offset}`, {
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setEventsPers(compareDataEvents(data));
        setTotalPages(Math.ceil(data.total_pages / 6));
      });
  }

  useEffect(() => {
    getEventsCards();
  }, [offset]);

  return (
    <>
      <HeaderAdmin setOpen={setOpen} isOpen={isOpen} />

      <div className="fio-title">
        <span className="last-name-user">{person?.last_name}</span>
        <span className="first-name-user">{person?.first_name}</span>
        <span className="surname-user">{person?.surname}</span>
        <img
          className="user-edit-img"
          src={editUser}
          alt="edit-user"
          onClick={() => setEditPerson(person)}
        />
      </div>

      <div className="user-info">
        <div className="user-login">
          <img src={userAvatar} alt="user-avatar" className="user-img-mr" />
          <div className="flex-column">
            <span className="log">Логин</span>
            <span className="mail">{person?.username}</span>
          </div>
        </div>

        <div className="user-code">
          <img src={userCode} alt="user-code" className="user-img-mr" />
          <div className="flex-column">
            <span className="code">Код доступа</span>
            <span className="mail">
              {person?.id}
              {person?.access_code}
            </span>
          </div>
        </div>

        <div className="user-date">
          <img src={userDate} alt="user-date" className="user-img-mr" />
          <div className="flex-column">
            <span className="date-title">Даты заезда</span>
            {!person?.start_date && !person?.end_date ? (
              <span className="dates">даты не выбраны</span>
            ) : (
              <span className="dates">
                {person && parseDate(person?.start_date)}-
                {person && parseDate(person?.end_date)}
              </span>
            )}
          </div>
        </div>

        <div className="user-home">
          <img src={userHome} alt="user-home" className="user-img-mr" />
          <div className="flex-column">
            <span className="home-title">Дом</span>
            <span className="home-number">{person?.room?.house?.number}</span>
          </div>
        </div>

        <div className="user-room">
          <img src={userRoom} alt="user-room" className="user-img-mr" />
          <div className="flex-column">
            <span className="room-title">Комната</span>
            <span className="room-numb">{person?.room?.number}</span>
          </div>
        </div>

        <div className="user-points" onClick={() => setAddPoints(true)}>
          <img src={userPoints} alt="user-points" className="user-img-mr" />
          <div className="flex-column">
            <span className="points-title">Баллы</span>
            <span className="points-numb">{person?.stars}</span>
          </div>
        </div>
      </div>

      <div className="user-events-container">
        {eventsPers &&
          eventsPers?.map((item) => (
            <EventCardDate items={item.items} date={item.date} />
          ))}
      </div>

      <div className="pagination-container">
        <ul className="pagination-list">
          <button
            className="btn-left"
            disabled={offset <= 0}
            onClick={() => setOffset((prev) => prev - 6)}
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.8438 7H4.67375L10.2638 1.41L8.84375 0L0.84375 8L8.84375 16L10.2537 14.59L4.67375 9H16.8438V7Z"
                fill="#494B42"
              />
            </svg>
          </button>
          <li className="page-number">{Math.ceil(offset / 6) + 1}</li>
          <button
            className="btn-right"
            disabled={Math.ceil(offset / 6) + 1 === totalPages}
            onClick={() => setOffset((prev) => prev + 6)}
          >
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.3633 4L10.9533 5.41L16.5333 11H4.36328V13H16.5333L10.9533 18.59L12.3633 20L20.3633 12L12.3633 4Z"
                fill="#494B42"
              />
            </svg>
          </button>
        </ul>
      </div>

      <ModalEditPerson
        editPerson={editPerson}
        setEditPerson={setEditPerson}
        setPerson={setPerson}
        person={person}
      />
      <ModalAddPoints
        addPoints={addPoints}
        setAddPoints={setAddPoints}
        person={person}
        setPerson={setPerson}
      />
    </>
  );
}

export default PersonPage;
