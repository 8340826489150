import React from 'react'

function Events() {
    return (
        <div className='events'>
            <span className='menu-title'>Мероприятия</span>
            <span className='menu-edit'>Создать/редактировать</span>
        </div>
    )
}

export default Events;