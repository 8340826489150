import React from 'react'
import User from '../images/User.png'

function Header () {
    return (
        <div className='header'>
            <div className='header_title'>
                <div className='title'>АЛТАЙСКОЕ ПОДВОРЬЕ</div>
                <div className='subtitle'>Административная панель</div>
            </div>
            <img src={User} alt='user' />
        </div>
    )
}

export default Header;