import HeaderAdmin from "./../components/HeaderAdmin";
import { useEffect, useState } from "react";
import PersonCard from "./../components/PersonCard";
import addPerson from "./../images/TableCell.png";
import ModalAddPerson from "../components/ModalAddPerson";
import ModalDeletePerson from "../components/ModalDeletePerson";
import ModalDeleteGroup from "../components/ModalDeleteGroup";
import ModalCheckIn from "../components/ModalCheckIn";
import { useParams } from "react-router-dom";
import { baseHeaders, baseUrl, parseDate } from "../utils/utils";
import ModalHouses from "../components/ModalHouses";

function GroupList() {
  const [isOpen, setOpen] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [group, setGroup] = useState(null);
  const { id } = useParams();
  const [delPerson, setDelPerson] = useState(null);
  const [checkin, setCheckin] = useState(false);
  const [openHouses, setOpenHouses] = useState(false);

  function renderGroup() {
    fetch(`${baseUrl}/admin/groups/${id}`, {
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setGroup(data);
      });
  }

  useEffect(() => {
    renderGroup();
  }, [id]);

  function inviteUsers() {
    fetch(`${baseUrl}/admin/groups/${id}/invite`, {
      method: "POST",
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        group_id: group?.id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        alert("Коды успешно отправлены");
      });
  }

  return (
    <>
      <HeaderAdmin setOpen={setOpen} isOpen={isOpen} />

      <div className="period-wrap">
        <div className="group-period">
          <span className="date-group-period">
            {group && parseDate(group?.start_date)} –{" "}
            {group && parseDate(group?.end_date)}
          </span>

          <svg
            onClick={() => setModalOpen(true)}
            className="btn-delete-group"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40" height="40" rx="2" fill="#F7F8F5" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13 27C13 28.1 13.9 29 15 29H25C26.1 29 27 28.1 27 27V14H29V12H23V11C23 10.4477 22.5523 10 22 10H18C17.4477 10 17 10.4477 17 11V12H11V14H13V27ZM19 16V25H17V16H19ZM23 25V16H21V25H23Z"
              fill="#E64D4D"
            />
          </svg>
        </div>
        <div className="wraps">
          <div className="send-code" onClick={inviteUsers}>
            Разослать уникальные коды
          </div>
          <div className="list_rooms" onClick={() => setOpenHouses(true)}>
            Список номеров
          </div>
        </div>
      </div>

      <div className="list-wrap">
        <div className="header-list-wrap">
          <span className="list-item list-item-home">Дом</span>
          <span className="list-item list-item-room">Номер</span>
          <span className="list-item list-item-fio">ФИО</span>
          <span className="list-item list-item-mail">Почта</span>
          <span className="list-item list-item-start">Дата заезда</span>
          <span className="list-item list-item-end">Дата выезда</span>
          <span className="list-item list-item-end">Баллы</span>
        </div>

        {group?.users?.map((item) => (
          <PersonCard
            group={group}
            item={item}
            setDelPerson={setDelPerson}
            checkin={checkin}
            setCheckin={setCheckin}
          />
        ))}

        <div className="add-person">
          <span onClick={() => setModalActive(true)} className="add-person-bg">
            <img src={addPerson} alt="add-person" />
          </span>
          <span className="bg"></span>
        </div>
      </div>

      {group && (
        <ModalAddPerson
          modalActive={modalActive}
          setModalActive={setModalActive}
          groupStart={group?.start_date}
          groupEnd={group?.end_date}
          renderGroup={renderGroup}
        />
      )}
      <ModalDeletePerson
        delPerson={delPerson}
        setDelPerson={setDelPerson}
        renderGroup={renderGroup}
      />

      <ModalDeleteGroup openDelete={modalOpen} setOpenDelete={setModalOpen} />

      <ModalCheckIn
        checkin={checkin}
        setCheckin={setCheckin}
        renderGroup={renderGroup}
      />

      <ModalHouses openHouses={openHouses} setOpenHouses={setOpenHouses} />
    </>
  );
}

export default GroupList;
