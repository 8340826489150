import { Field, Form, Formik } from "formik";
import DatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import { baseHeaders, baseUrl } from "../utils/utils";

function ModalCheckIn({ checkin, setCheckin, renderGroup }) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [houses, setHouses] = useState([]);
  const [houseId, setHouseId] = useState(null);
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    fetch(`${baseUrl}/admin/houses`, {
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setHouses(data.items);
        setHouseId(data.items[0]?.id);
      });
  }, []);

  useEffect(() => {
    if (houseId) {
      fetch(`${baseUrl}/admin/houses/${houseId}`, {
        headers: {
          ...baseHeaders,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setRooms(data.rooms);
        });
    }
  }, [houseId]);

  useEffect(() => {
    setStartDate(
      checkin.start_date ? new Date(checkin.start_date) : new Date()
    );
    setEndDate(checkin.end_date ? new Date(checkin.end_date) : new Date());
  }, [checkin?.start_date, checkin?.end_date]);

  function sendCheckin(values) {
    fetch(`${baseUrl}/admin/users/${checkin?.id}/room/checkin`, {
      method: "PUT",
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        start_date: startDate.toISOString().split("T")[0],
        end_date: startDate.toISOString().split("T")[0],
        room_id: values.room_id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setCheckin(false);
        renderGroup();
      });
  }

  return (
    <div
      className={
        checkin ? "modal-wrap-checkin-group active" : "modal-wrap-checkin-group"
      }
    >
      <div className="modal-add-group">
        <div className="flex modal-style">
          <span className="modal-add-group-title">Заселить гостя</span>
          <svg
            onClick={() => setCheckin(false)}
            className="add-person-btn-close"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.75796 6.34375L6.34375 7.75797L10.5864 12.0006L6.34375 16.2432L7.75796 17.6575L12.0006 13.4148L16.2432 17.6575L17.6575 16.2432L13.4148 12.0006L17.6575 7.75796L16.2432 6.34375L12.0006 10.5864L7.75796 6.34375Z"
              fill="white"
            />
          </svg>
        </div>
        <Formik
          initialValues={{
            username: checkin?.username,
            first_name: checkin?.first_name,
            last_name: checkin?.last_name,
            surname: checkin?.surname,
            start_date: checkin?.star_date,
            end_date: checkin?.end_date,
            room_id: 0,
            role: "",
            stars: 0,
            group_id: 0,
          }}
          onSubmit={sendCheckin}
          enableReinitialize={true}
        >
          {({ setFieldValue }) => (
            <Form className="formwrap-add-person">
              <div className="fio-wrap flex">
                <div className="margin-right">
                  <label htmlFor={"last_name"} className="label-style-person">
                    Фамилия
                  </label>
                  <br />
                  <Field
                    className="add-person-fio"
                    name={"last_name"}
                    placeholder="Иванов"
                    autoComplete="off"
                  />
                </div>
                <div className="margin-right">
                  <label htmlFor={"first_name"} className="label-style-person">
                    Имя
                  </label>
                  <br />
                  <Field
                    className="add-person-fio"
                    name={"first_name"}
                    placeholder="Иван"
                    autoComplete="off"
                  />
                </div>
                <div className="margin-right">
                  <label htmlFor={"surname"} className="label-style-person">
                    Отчество
                  </label>
                  <br />
                  <Field
                    className="add-person-fio"
                    name={"surname"}
                    placeholder="Иванович"
                    autoComplete="off"
                  />
                </div>
              </div>
              <label htmlFor={"username"} className="label-style-person">
                Почта
              </label>
              <br />
              <Field
                className="add-person-mail"
                name={"username"}
                placeholder="ivan.ii@gazprom-neft.ru"
                autoComplete="off"
              />
              <div className="flex">
                <div className="home-room">
                  <label htmlFor={"home"} className="label-style-person">
                    Дом
                  </label>
                  <br />
                  <Field
                    onChange={(event) => {
                      setFieldValue("home", event.target.value);
                      setHouseId(event.target.value);
                    }}
                    className="select-home"
                    as="select"
                    name={"home"}
                    placeholder="Выберите дом"
                  >
                    {houses.map((item) => (
                      <option className="style-option" value={item.id}>
                        {item.number}
                      </option>
                    ))}
                  </Field>
                </div>
                <div>
                  <label htmlFor={"room"} className="label-style-person">
                    Комната
                  </label>
                  <br />
                  <Field className="select-room" as="select" name={"room_id"}>
                    {rooms.map((item) => (
                      <option className="style-option" value={item.id}>
                        {item.number}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>

              <div className="flex wrap-datepicker">
                <div className="flex-start">
                  <label htmlFor={"start_date"} className="label-style-person">
                    Дата заезда
                  </label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    name={"start_date"}
                    locale="ru"
                  />
                </div>
                <div className="flex-start">
                  <label htmlFor={"end_date"} className="label-style-person">
                    Дата заезда
                  </label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    name={"end_date"}
                    locale="ru"
                  />
                </div>
              </div>

              <div className="btn-wrap-add-person">
                <button
                  onClick={() => setCheckin(false)}
                  className="btn-add-person-cancel"
                >
                  Отменить
                </button>
                <button type="submit" className="btn-add-person-save">
                  Сохранить
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ModalCheckIn;
