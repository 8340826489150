import React from 'react'

function Groups() {
    return (

        <div className='groups'>
            <span className='menu-title'>Группы</span>
            <span className='menu-edit'>Создать/редактировать</span>
        </div>
    )
}

export default Groups;