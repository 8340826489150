import { Route, Routes } from "react-router-dom";
import "./App.css";
import Main from "./pages/Main";
import Nav from "./pages/Nav";
import GroupsPage from "./pages/GroupsPage";
import GroupList from "./pages/GroupList";
import ProtectedRoute from "./components/ProtectedRoute";
import EventsPage from "./pages/EventsPage";
import EventDescr from "./pages/EventDescr";
import { useState } from "react";
import PersonPage from "./pages/PersonPage";
import RestPage from "./pages/RestPage";
import CreateCourse from "./pages/CreateСourse";
import FeedbackPage from "./pages/FeedbackPage";
import FeedbackDescr from "./pages/FeedbackDescr";

function App() {
  const [editEvent, setEditEvent] = useState(false);
  const [editCourse, setEditCourse] = useState(false);
  const [deleteCourse, setDeleteCourse] = useState(false);

  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<Main />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Nav />
            </ProtectedRoute>
          }
        />
        <Route
          path="/groups"
          element={
            <ProtectedRoute>
              <GroupsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/group_list/:id"
          element={
            <ProtectedRoute>
              <GroupList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/events"
          element={
            <ProtectedRoute>
              <EventsPage editEvent={editEvent} setEditEvent={setEditEvent} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/event_descr/:id/:date"
          element={
            <ProtectedRoute>
              <EventDescr editEvent={editEvent} setEditEvent={setEditEvent} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/person_descr/:id"
          element={
            <ProtectedRoute>
              <PersonPage />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/restaurant"
          element={
            <ProtectedRoute>
              <RestPage
                deleteCourse={deleteCourse}
                setDeleteCourse={setDeleteCourse}
                editCourse={editCourse}
                setEditCourse={setEditCourse}
              />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/courses"
          element={
            <ProtectedRoute>
              <CreateCourse
                editCourse={editCourse}
                setEditCourse={setEditCourse}
              />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/feedback"
          element={
            <ProtectedRoute>
              <FeedbackPage />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/feedback_descr/:id/:start_date/:end_date"
          element={
            <ProtectedRoute>
              <FeedbackDescr />
            </ProtectedRoute>
          }
        ></Route>
      </Routes>
    </div>
  );
}

export default App;
