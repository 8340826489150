import { Field, Formik, Form, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useLocation } from "react-router-dom";
import { baseHeaders, baseUrl } from "../utils/utils";
import * as Yup from "yup";

function ModalEditEventBtn({
  editEvent,
  setEditEvent,
  getCardsEvent,
  getDescr,
}) {
  const [startDate, setStartDate] = useState(new Date());
  const location = useLocation();

  const validationsSchema = Yup.object().shape({
    name: Yup.string().required("Поле не заполнено"),
    instructor_link: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Неправильно указана ссылка"
    ),
    description: Yup.string().required("Поле не заполнено"),
    date: Yup.string().required("Дата проведения не выбрана"),
    time_start: Yup.string().required("Время начала не выбрано"),
    time_end: Yup.string().required("Время окончания не выбрано"),
    place: Yup.string().required("Поле не заполнено"),
    capacity: Yup.string().required("Поле не заполнено"),
  });

  useEffect(() => {
    setStartDate(editEvent?.date ? new Date(editEvent?.date) : new Date());
  }, [editEvent?.date]);

  function sendEditEvent(values) {
    if (!values.instructor_link) {
      values.instructor_link = null;
    }
    if (values.repeat === "null") {
      values.repeat = null;
    }
    fetch(`${baseUrl}/admin/events/${editEvent?.id}`, {
      method: "PATCH",
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        ...values,
        date: startDate.toISOString().split("T")[0],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setEditEvent(false);
        if (location.pathname === "/events") {
          getCardsEvent();
        } else {
          getDescr();
        }
      });
  }

  return (
    <div
      className={
        editEvent
          ? "modal-wrap-add-event-btn active"
          : "modal-wrap-add-event-btn"
      }
    >
      <div className="modal-add-event">
        <div className="event-wrap margin-bottom">
          <span className="create-event-title">Редактировать мероприятие</span>
          <svg
            onClick={() => setEditEvent(false)}
            className="add-event-btn-close"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.75796 6.34375L6.34375 7.75797L10.5864 12.0006L6.34375 16.2432L7.75796 17.6575L12.0006 13.4148L16.2432 17.6575L17.6575 16.2432L13.4148 12.0006L17.6575 7.75796L16.2432 6.34375L12.0006 10.5864L7.75796 6.34375Z"
              fill="#F7F8F5"
            />
          </svg>
        </div>
        <Formik
          initialValues={{
            instructor_link: editEvent?.instructor_link || "",
            name: editEvent?.name,
            description: editEvent?.description,
            time_start: editEvent?.time_start,
            time_end: editEvent?.time_end,
            place: editEvent?.place,
            capacity: editEvent?.capacity,
            repeat: editEvent?.repeat,
            date: editEvent?.date,
          }}
          enableReinitialize={true}
          onSubmit={sendEditEvent}
          validationSchema={validationsSchema}
        >
          {({ setFieldValue, dirty, isValid }) => (
            <Form>
              <div className="flex margin-bottom">
                <div className="flex-column">
                  <label htmlFor={"name"} className="label-style-person">
                    Название мероприятия
                  </label>
                  <Field
                    className="add-event-name"
                    name={"name"}
                    placeholder="Назовите мероприятие"
                    autocomplete="off"
                  />
                  <ErrorMessage
                    component={`p`}
                    name={`name`}
                    className="err_message"
                  />
                </div>
                <div className="flex-column">
                  <label
                    htmlFor={"instructor_link"}
                    className="label-style-person"
                  >
                    Инструктор
                  </label>
                  <Field
                    className="add-event-telegram"
                    name={"instructor_link"}
                    placeholder="https://t.me/Polinaglamping"
                    autocomplete="off"
                  />
                  <ErrorMessage
                    component={`p`}
                    name={`instructor_link`}
                    className="err_message"
                  />
                </div>
              </div>
              <div className="flex-column margin-bottom">
                <label htmlFor={"description"} className="label-style-person">
                  Описание
                </label>
                <Field
                  as="textarea"
                  className="add-event-descr"
                  name={"description"}
                  placeholder="Опишите мероприятие"
                  autocomplete="off"
                />
                <ErrorMessage
                  component={`p`}
                  name={`description`}
                  className="err_message"
                />
              </div>
              <div className="flex margin-bottom">
                <div className="flex-column">
                  <label htmlFor={"date"} className="label-style-person">
                    Дата проведения
                  </label>
                  <DatePicker
                    selected={startDate}
                    selectsStart
                    // startDate={startDate}
                    // endDate={endDate}
                    locale="ru"
                    onChange={(date) => {
                      setStartDate(date);
                      setFieldValue("date", date.toISOString().split("T")[0]);
                    }}
                    name={"date"}
                    dateFormat={"dd.MM.yyyy"}
                  />
                  <ErrorMessage
                    component={`p`}
                    name={`date`}
                    className="err_message"
                  />
                </div>
                <div className="flex-column">
                  <label htmlFor={"repeat"} className="label-style-person">
                    Повтор
                  </label>
                  <Field
                    as="select"
                    className="add-event-repeat"
                    name={"repeat"}
                    placeholder="Выберите период"
                    autocomplete="off"
                  >
                    <option value="null">Никогда</option>
                    <option value="EVERY_DAY">Каждый день</option>
                    <option value="EVERY_WEEK">Каждую неделю</option>
                    <option value="EVERY_TWO_WEEKS">Каждые две недели</option>
                    <option value="EVERY_MONTH">Каждый месяц</option>
                    <option value="EVERY_YEAR">Каждый год</option>
                  </Field>
                  <ErrorMessage
                    component={`p`}
                    name={`repeat`}
                    className="err_message"
                  />
                </div>
              </div>
              <div className="flex margin-bottom">
                <div className="flex-column">
                  <label htmlFor={"time_start"} className="label-style-person">
                    Время начала
                  </label>
                  <Field
                    type="time"
                    className="add-event-time-start"
                    name={"time_start"}
                    placeholder="09:00"
                  />
                  <ErrorMessage
                    component={`p`}
                    name={`time_start`}
                    className="err_message"
                  />
                </div>
                <div className="flex-column">
                  <label htmlFor={"time_end"} className="label-style-person">
                    Время окончания
                  </label>
                  <Field
                    type="time"
                    className="add-event-time-end"
                    name={"time_end"}
                    placeholder="12:00"
                  />
                  <ErrorMessage
                    component={`p`}
                    name={`time_end`}
                    className="err_message"
                  />
                </div>
              </div>
              <div className="flex margin-bottom">
                <div className="flex-column">
                  <label htmlFor={"place"} className="label-style-person">
                    Место проведения
                  </label>
                  <Field
                    className="add-event-place"
                    name={"place"}
                    placeholder="Напишите место проведения"
                    autocomplete="off"
                  ></Field>
                  <ErrorMessage
                    component={`p`}
                    name={`place`}
                    className="err_message"
                  />
                </div>
                <div className="flex-column">
                  <label htmlFor={"capacity"} className="label-style-person">
                    Количество человек
                  </label>
                  <Field
                    className="add-event-meet"
                    name={"capacity"}
                    placeholder="Ограничение по количеству"
                    autocomplete="off"
                  ></Field>
                  <ErrorMessage
                    component={`p`}
                    name={`capacity`}
                    className="err_message"
                  />
                </div>
              </div>
              <div className="btn-event-wrap">
                <button
                  onClick={() => setEditEvent(false)}
                  className="btn-event-cancel"
                >
                  Отменить
                </button>
                <button
                  type="submit"
                  className={
                    !(dirty && isValid)
                      ? "btn-event-save"
                      : "btn-event-save active"
                  }
                >
                  Сохранить
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ModalEditEventBtn;
