import { useEffect, useState } from "react";
import CourseCard from "../components/CourseCard";
import SearchIcon from "./../images/search-icon.png";
import { baseUrl, baseHeaders } from "../utils/utils";
import ModalDeleteCourse from "./ModalDeleteCourse";
import ModalEditCourse from "./ModalEditCourse";

function AllCourses({
  allCourses,
  day,
  setDeleteCourse,
  deleteCourse,
  editCourse,
  setEditCourse,
}) {
  const [dishes, setDishes] = useState([]);
  const [value, setValue] = useState("");

  function getDishes() {
    fetch(`${baseUrl}/admin/menu?day=${(day = 1)}`, {
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setDishes(data);
      });
  }

  useEffect(() => {
    getDishes();
  }, [day]);

  return (
    <>
      <div className={allCourses ? "all-hidden active" : "all-hidden"}>
        <div className="search-course-wrap">
          <i class="search-icon" aria-hidden="true">
            <img src={SearchIcon} alt="alt" />
          </i>
          <input
            onChange={(event) => setValue(event.target.value)}
            value={value}
            className="search-course"
            searchtype="text"
            placeholder="Найти блюдо"
          ></input>
        </div>

        <div className="courses-wrap-container">
          <h1 className="mini-title">Завтрак</h1>
          <div className="breakfast-container">
            {dishes?.breakfast?.dishes
              ?.filter((element) =>
                element.name.toLowerCase().includes(value.toLowerCase())
              )
              ?.map((item) => (
                <CourseCard
                  deleteCourse={deleteCourse}
                  setDeleteCourse={setDeleteCourse}
                  item={item}
                  editCourse={editCourse}
                  setEditCourse={setEditCourse}
                />
              ))}
          </div>
          <h1 className="mini-title">Обед</h1>
          <div className="lunch-container">
            {dishes?.lunch?.dishes
              ?.filter((element) =>
                element.name.toLowerCase().includes(value.toLowerCase())
              )
              ?.map((item) => (
                <CourseCard
                  deleteCourse={deleteCourse}
                  setDeleteCourse={setDeleteCourse}
                  item={item}
                  editCourse={editCourse}
                  setEditCourse={setEditCourse}
                />
              ))}
          </div>
          <h1 className="mini-title">Ужин</h1>
          <div className="dinner-container">
            {dishes?.dinner?.dishes
              ?.filter((element) =>
                element.name.toLowerCase().includes(value.toLowerCase())
              )
              ?.map((item) => (
                <CourseCard
                  deleteCourse={deleteCourse}
                  setDeleteCourse={setDeleteCourse}
                  item={item}
                  editCourse={editCourse}
                  setEditCourse={setEditCourse}
                />
              ))}
          </div>
        </div>
      </div>

      <ModalDeleteCourse
        deleteCourse={deleteCourse}
        setDeleteCourse={setDeleteCourse}
        getDishes={getDishes}
      />

      <ModalEditCourse
        editCourse={editCourse}
        setEditCourse={setEditCourse}
        getDishes={getDishes}
      />
    </>
  );
}

export default AllCourses;
