import React from "react";
import cover from "./../images/cover.png";
import Groups from "./../components/Groups";
import Events from "./../components/Events";
import Rest from "./../components/Rest";
import { Link } from "react-router-dom";

function AdminMenu({ isOpen, setOpen }) {
  return (
    <div className={isOpen ? "cover-menu_wrap blur" : "cover-menu_wrap"}>
      <img className="cover-margin" src={cover} alt="cover" />
      <div className="menu-items">
        <Link to="/groups">
          <Groups />
        </Link>
        <Link to="/events">
          <Events />
        </Link>
        <Link to="/restaurant">
          <Rest />
        </Link>
      </div>
    </div>
  );
}

export default AdminMenu;
