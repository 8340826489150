import editUser from "../images/edit-descr.png";
import cancelDescr from "../images/cancel-descr.png";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function CourseCard({
  setEditCourse,
  setDeleteCourse,
  item,
  setDishesCard,
}) {
  const location = useLocation();
  const [selected, setSelected] = useState(item.selected);

  useEffect(() => {
    setSelected(item.selected);
  }, [item.selected]);

  function handleChange(event) {
    setSelected((prev) => !prev);
    if (event.target.checked) {
      setDishesCard((prev) => [...prev, item.id]);
    } else {
      setDishesCard((prev) => prev.filter((id) => id !== item.id));
    }
  }

  return (
    <div className="course-card">
      {location.pathname !== "/restaurant" && (
        <input
          checked={selected}
          onChange={handleChange}
          className="for-all-checkbox"
          type="checkbox"
        />
      )}
      <span className="course-card-title">{item?.name}</span>
      <span className="course-card-descr">{item?.description}</span>
      <div className="edit-wrap">
        <img
          className="course-edit"
          src={editUser}
          alt="alt"
          onClick={() => setEditCourse(item)}
        />

        {location.pathname !== "/courses" && (
          <img
            className="course-delete"
            src={cancelDescr}
            alt="alt"
            onClick={() => setDeleteCourse(item)}
          />
        )}
      </div>
    </div>
  );
}

export default CourseCard;
