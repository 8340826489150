import { useEffect, useState } from "react";
import { baseHeaders, baseUrl } from "../utils/utils";
import RoomCard from "./RoomCard";
import { useParams } from "react-router-dom";
import ModalEditPerson from "./ModalEditPerson";

function ModalHouses({ openHouses, setOpenHouses }) {
  const [houses, setHouses] = useState([]);
  const [houseId, setHouseId] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [offset, setOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const { id } = useParams();
  const [editPerson, setEditPerson] = useState(null);

  useEffect(() => {
    fetch(`${baseUrl}/admin/houses?free=true&group_id=${id}`, {
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setHouses(data.items);
        setHouseId(data.items[0]?.id);
      });
  }, []);

  useEffect(() => {
    if (houseId) {
      fetch(`${baseUrl}/admin/houses/${houseId}?offset=${offset}&limit=8`, {
        headers: {
          ...baseHeaders,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setRooms(data);
          setTotalPages(data.rooms.length);
          console.log(totalPages);
        });
    }
  }, [houseId, offset]);

  return (
    <>
      <div
        className={
          openHouses ? "modal-wrap-houses active" : "modal-wrap-houses"
        }
      >
        <div className="modal-houses">
          <div className="house-number-container">
            {houses?.map((item) => (
              <span
                className={
                  houseId === item.id ? "house-number active" : "house-number"
                }
                onClick={() => setHouseId(item.id)}
              >
                Дом
              </span>
            ))}

            <svg
              onClick={() => setOpenHouses(false)}
              className="modal-houses-close"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.75796 6.34375L6.34375 7.75797L10.5864 12.0006L6.34375 16.2432L7.75796 17.6575L12.0006 13.4148L16.2432 17.6575L17.6575 16.2432L13.4148 12.0006L17.6575 7.75796L16.2432 6.34375L12.0006 10.5864L7.75796 6.34375Z"
                fill="#F7F8F5"
              />
            </svg>
          </div>
          <div className="rooms-card-container">
            {rooms?.rooms?.map((item) => (
              <RoomCard
                item={item}
                room={rooms}
                editPerson={editPerson}
                setEditPerson={setEditPerson}
              />
            ))}
          </div>

          <div className="pagination-container">
            <ul className="pagination-list">
              <button
                className="btn-left-rooms"
                disabled={offset <= 0}
                onClick={() => setOffset((prev) => prev - 8)}
              >
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.8438 7H4.67375L10.2638 1.41L8.84375 0L0.84375 8L8.84375 16L10.2537 14.59L4.67375 9H16.8438V7Z"
                    fill="#CFD0CA"
                  />
                </svg>
              </button>
              <li className="page-number-rooms">{Math.ceil(offset / 8) + 1}</li>
              <button
                className="btn-right-rooms"
                disabled={
                  Math.ceil(offset / 8) + 1 >= Math.ceil(rooms.total_pages)
                }
                // disabled={Math.ceil(offset / 8) + 1 === totalPages}
                onClick={() => setOffset((prev) => prev + 8)}
              >
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.3633 4L10.9533 5.41L16.5333 11H4.36328V13H16.5333L10.9533 18.59L12.3633 20L20.3633 12L12.3633 4Z"
                    fill="#CFD0CA"
                  />
                </svg>
              </button>
            </ul>
          </div>
        </div>
      </div>
      <ModalEditPerson
        editPerson={editPerson}
        setEditPerson={setEditPerson}
        setRooms={setRooms}
      />
    </>
  );
}

export default ModalHouses;
