import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { baseHeaders, baseUrl } from "../utils/utils";

function ModalAddPoints({ addPoints, setAddPoints, person, setPerson }) {
  const validationsSchema = Yup.object().shape({
    stars: Yup.number("Введите число").required("Поле не заполнено"),
  });

  function AddPoints(values) {
    fetch(`${baseUrl}/admin/users/${person.id}/stars`, {
      method: "PATCH",
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        user_id: person.id,
        stars: values.stars,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setPerson(data);
        setAddPoints(false);
      });
  }

  return (
    <div
      className={
        addPoints ? "modal-wrap-add-group active" : "modal-wrap-add-group"
      }
    >
      <div className="modal-points modal-add-group">
        <div className="flex">
          <span className="title-points">Добавить баллы</span>
          <svg
            onClick={() => setAddPoints(false)}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.75796 6.34375L6.34375 7.75797L10.5864 12.0006L6.34375 16.2432L7.75796 17.6575L12.0006 13.4148L16.2432 17.6575L17.6575 16.2432L13.4148 12.0006L17.6575 7.75796L16.2432 6.34375L12.0006 10.5864L7.75796 6.34375Z"
              fill="white"
            />
          </svg>
        </div>
        <span className="points-text">Баллы</span>
        <Formik
          initialValues={{
            stars: person?.stars,
          }}
          onSubmit={AddPoints}
          validationSchema={validationsSchema}
        >
          {({ dirty, isValid }) => (
            <Form>
              <Field
                className="add-points-input"
                type="text"
                placeholerText="Введите количесво баллов"
                name={"stars"}
              />
              <ErrorMessage
                component={`p`}
                name={`stars`}
                className="err_message"
              />
              <div className="btn-wrap-add-person">
                <span
                  className="btn-add-person-cancel"
                  onClick={() => setAddPoints(false)}
                >
                  Отменить
                </span>
                <button
                  type="submit"
                  className={
                    !(dirty && isValid)
                      ? "btn-add-person-save"
                      : "btn-add-person-save-active"
                  }
                >
                  Сохранить
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ModalAddPoints;
