import HeaderAdmin from './../components/HeaderAdmin'
import AdminMenu from './../components/AdminMenu'
import { useState } from 'react';

function Nav() {
    const [isOpen, setOpen] = useState(false)


    return (
        <>
        <HeaderAdmin isOpen={isOpen} setOpen={setOpen} />

        <AdminMenu isOpen={isOpen} setOpen={setOpen} />
        </>
    )
}

export default Nav;