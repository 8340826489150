import Header from './../components/Header'
import Login from './../components/Login'

function Main () {
    return (
        <>
            <Header />
            <Login />
        </>
    )
}

export default Main;