import { useNavigate, useParams } from "react-router-dom";
import { baseHeaders, baseUrl } from "../utils/utils";

function ModalDeleteGroup({ openDelete, setOpenDelete }) {
  const { id } = useParams();
  const navigate = useNavigate();

  function deleteGroup() {
    fetch(`${baseUrl}/admin/groups/${id}`, {
      method: "DELETE",
      headers: {
        ...baseHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setOpenDelete(false);
        navigate(`/groups`);
      });
  }

  return (
    <div
      class={
        openDelete
          ? "modal-wrap-delete-group active"
          : "modal-wrap-delete-group"
      }
    >
      <div className="modal-delete-person">
        <div className="modal-style-delete-person">
          <span className="modal-delete-person-title">Удаление группы</span>
          <svg
            onClick={() => setOpenDelete(false)}
            className="add-person-btn-close"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.75796 6.34375L6.34375 7.75797L10.5864 12.0006L6.34375 16.2432L7.75796 17.6575L12.0006 13.4148L16.2432 17.6575L17.6575 16.2432L13.4148 12.0006L17.6575 7.75796L16.2432 6.34375L12.0006 10.5864L7.75796 6.34375Z"
              fill="#494B42"
            />
          </svg>
        </div>
        <div className="center">
          <p className="delete-person-question">
            Вы точно хотите удалить группу?
          </p>
          <p className="delete-person-text">
            Это действие нельзя будет отменить
          </p>
        </div>
        <div className="btn-wrap-delete-person">
          <span onClick={deleteGroup} className="btn-delete-person">
            Удалить
          </span>
          <span
            className="btn-cancel-person"
            onClick={() => setOpenDelete(false)}
          >
            Отменить
          </span>
        </div>
      </div>
    </div>
  );
}

export default ModalDeleteGroup;
